<template>
    <div 
        v-if="!loaded || banner"
        class="bna p-3" 
        :class="{'w-100-promo': wide}"
    >
        <button 
            v-if="canEdit"
            class="btn-bg btn-round icon-ic-pencil edit-btn" 
            @click="$emit('openEditor')"
        />

        <div v-if="banner">
            <a
                target="_blank"
                rel="noopener nofollow"
                :href="banner.link" 
                class="img"
                @click="click"
            >
                <WebpPicture
                    :src="banner.image"
                    alt=""
                    loading="lazy"
                    width="240"
                    height="136"
                    imgClass="block-image"
                />
            </a>

            <p class="fw-700 mt-2 heading">
                {{ banner.title }}
            </p>

            <p class="f-small mt-1 mb-0 text">
                {{ banner.text }}
            </p>

            <div class="timer mt-2" v-if="end && end > new Date()">
                <div class="fw-700 mr-h timer-label">{{ banner.timerLabel }}:</div>
                <div class="countdown ff-monospace">
                    <div class="mesure">{{ left.days }}</div>:
                    <div class="mesure">{{ left.hours }}</div>:
                    <div class="mesure">{{ left.minutes }}</div>:
                    <div class="mesure">{{ left.seconds }}</div>
                </div>
            </div>

            <a
                target="_blank"
                rel="noopener nofollow"
                :href="banner.link"
                class="btn-bg tt-upper fw-700 mt-2"
                @click="click"
            >
                {{ banner.button }}
            </a>
        </div>

        <div class="skeleton" v-else>
            <div class="img mb-2" />

            <div class="heading" />
            <div class="heading" />

            <div class="text mt-1" />
            <div class="text" />
            <div class="text" />

            <div class="s-btn mt-2" />
        </div>
    </div>
</template>

<script>
    import WebpPicture from '~/components/WebpPicture.vue';
    export default {
        components: {
            WebpPicture
        },

        props: ['defBanner', 'canEdit', 'wide'],

        data () {
            return {
                loaded: false,
                banner: null,
                end: null,
                timer: null,
                left: {
                    days: '00',
                    hours: '00',
                    minutes: '00',
                    seconds: '00'
                },
            }
        },

        created () {
            if (this.defBanner) {
                this.banner = this.defBanner;
                this.loaded = true;

                if (this.banner && this.banner.timerEnd) {
                    this.end = new Date(this.banner.timerEnd);
                    this.startTimer();
                }
            } else {
                this.loadBanner();
            }

            this.updateTimerState();
        },

        mounted () {
            if (this.end) this.startTimer();
        },

        methods: {
            loadBanner () {
                let page = this.$route.fullPath;
                this.$axios.$get('/banner/find', {
                    params: {
                        limit: 1,
                        random: 1,
                        active: 1,
                        page
                    },
                    progress: false
                })
                .then(res => {
                    if (res && res[0]) this.banner = res[0];
                    this.loaded = true;

                    if (this.banner && this.banner.timerEnd) {
                        this.end = new Date(this.banner.timerEnd);
                        this.startTimer();
                    }
                })
                .catch(err => console.log(err));
                
            },


            startTimer () {
                if (!process.browser) return;
                this.updateTimerState();

                this.timer = setInterval(() => {
                    this.updateTimerState();
                }, 1000)
            },

            updateTimerState () {
                const ended = this.end < new Date();

                if (ended) {
                    clearInterval(this.timer);
                } else {
                    this.updateTime();
                }
            },

            updateTime () {
                // Update timer
                let now      = new Date();
                let interval = this.end.getTime() - now.getTime();
                let seconds  = Math.floor(interval / 1000);
                let minutes  = Math.floor(seconds / 60);
                let hours    = Math.floor(minutes / 60);
                let days     = Math.floor(hours / 24);

                hours   = hours   % 24;
                minutes = minutes % 60;
                seconds = seconds % 60;

                this.left = {
                    days:    ("00" + days).slice(-2),
                    hours:   ("00" + hours).slice(-2),
                    minutes: ("00" + minutes).slice(-2),
                    seconds: ("00" + seconds).slice(-2)
                };
            },

            click () {
                this.$axios.$get(`/banner/click/${this.banner.id}`, {
                    progress: false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bna {
        background-color: var(--color-cases-purple-dim);
        border-radius: 8px;
        width: 288px;

        position: relative;
    }

    .w-100-promo {
        width: 100%;

        .img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .edit-btn {
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .img {
        display: block;
        overflow: hidden;
        border-radius: 8px;
        max-width: 240px;
        aspect-ratio: 16 / 9;

        background-color: transparent;

        transition: box-shadow .35s ease;

        box-shadow: 0 0 12px var(--color-cases-shadow-1),
                    0 3px 12px var(--color-cases-secondary-button-shadow-1);

        &:hover {
            box-shadow: 0 0 16px var(--color-cases-shadow),
                        0 10px 16px -4px var(--color-cases-overlay-button);
        }

        &:active {
            transition: box-shadow .15s ease;
            box-shadow: 0 0 0 var(--color-cases-shadow),
                        0 0px 0px -4px var(--color-cases-overlay-button);
        }

        :deep(img) {
            width: 100%;
            max-width: 240px;
            height: auto;
        }
    }

    .heading {
        line-height: 20px;
        letter-spacing: -0.32px;

        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        max-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .text {
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        max-height: 64px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .timer {
        border-radius: 8px;
        margin-top: 4px;
        padding: 8px 4px;

        font-size: 14px;
        line-height: 17px;

        background: #E4DDF2;

        display: flex;

        align-items: center;
        justify-content: center;
    }

    @media (prefers-color-scheme: dark) {
        .timer {
            background: rgba(127, 116, 153, 0.50);
        }
    }

    [data-theme='dark'] {
        .timer {
            background: rgba(127, 116, 153, 0.50);
        }
    }

    [data-theme='light'] {
        .timer {
            background: #E4DDF2;
        }
    }

    .timer-label {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }


    .countdown {
        display:   flex;
        flex-flow: row nowrap;

        font-size: 14px;
        font-style: normal;

        text-align: left;
        // min-width: 90px;
    }

    .skeleton {
        .img,
        .text,
        .heading,
        .s-btn {
            background-color: var(--color-cases-gray-4);
            width: 100%;
            pointer-events: none;
            box-shadow: none;
        }

        .text {
            height: 12px;
            margin: 2px 0;
            border-radius: 2px;
        }

        .heading {
            height: 16px;
            margin: 2px 0;
            border-radius: 2px;
        }

        .s-btn {
            height: 32px;
            border-radius: 20px;
        }
    }
</style>
